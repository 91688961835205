import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "picker", "content"]
  static classes = ["active"]
  static values = { axis: String }

  connect() {
    const intervalId = setInterval(carouse, 10000, this.axisValue, this.activeClasses, this.contentTarget, this.containerTarget)
    this.containerTarget.setAttribute("data-interval-id", intervalId)

    const observer = new IntersectionObserver(this.playWhenEnters, { rootMargin: '0px', threshold: 0.1 })
    const players = document.querySelectorAll("lottie-player")
    players.forEach((player) => { observer.observe(player) })
  }

  playWhenEnters(entries, observer){
    entries.forEach(entry => {
      if(entry.isIntersecting) {
        entry.target.play();
      } else {
        entry.target.stop();
      }
    })
  }

  pick(event) {
    this.pickerTargets.forEach(t => t.classList.remove(...this.activeClasses))
    event.currentTarget.classList.add(...this.activeClasses)
    this.contentTarget.style.transform = `translate${this.axisValue}(${event.currentTarget.getAttribute("data-transition")})`
    clearInterval(this.containerTarget.dataset.intervalId)
  }
}

function carouse(axisValue, activeClasses, contentTarget, containerTarget){
  const pickerTargets = document.querySelectorAll("[data-sliding-picker-index-param]")
  const numberOfElems = pickerTargets.length
  let nextActiveElementIdx, previousActiveIndex, nextActiveElement, nextAnimationTime
  if (numberOfElems > 0){
    pickerTargets.forEach((elem) => {
      if (elem.classList.contains(...activeClasses)) {
        elem.classList.remove(...activeClasses)
        nextAnimationTime = parseInt(elem.dataset.slidingPickerTransitionDelayParam)
        previousActiveIndex = parseInt(elem.dataset.slidingPickerIndexParam)
        if (previousActiveIndex === numberOfElems){
          nextActiveElementIdx = 1
        } else {
          nextActiveElementIdx = previousActiveIndex + 1
        }
      }
    })
    nextActiveElement = document.querySelector(`[data-sliding-picker-index-param='${nextActiveElementIdx}']`)
    nextActiveElement.classList.add(...activeClasses)
    const player = document.querySelector(`[data-lottie-index="${nextActiveElementIdx}"]`)
    player.style.display = "none"
    player.style.display = ""
    contentTarget.style.transform = `translate${axisValue}(${nextActiveElement.getAttribute("data-transition")})`
  }
  clearInterval(containerTarget.getAttribute("data-interval-id"))
  const intervalId = setInterval(carouse, nextAnimationTime, axisValue, activeClasses, contentTarget, containerTarget)
  containerTarget.setAttribute("data-interval-id", intervalId)
}