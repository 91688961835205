import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectAllButton", "productCheckbox", "errorContainer", "submitButton"]

  connect() {
    // Capture UTM parameters on page load
    this.captureUTMParamsOnce();

    // Apply UTM parameters to the form if the modal opens
    this.applyStoredUTMParamsToForm();

    // Bind the checkbox handling logic
    this.bindSectionButtons();

    // Capture the IP address and append it to the form
    this.captureIPAddress();
  }

  // Capture IP address and append it as a hidden field in the form
  captureIPAddress() {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        const form = document.querySelector('#customHubspotForm');
        if (!form) return;

        // Create and append the hidden field for IP address
        const ipAddressField = document.createElement('input');
        ipAddressField.type = 'hidden';
        ipAddressField.name = 'ipAddress';
        ipAddressField.value = data.ip;
        form.appendChild(ipAddressField);
      })
      .catch((error) => console.error('Error fetching IP address:', error));
  }

  // Capture UTM parameters from URL and store in localStorage if not already stored
  captureUTMParamsOnce() {
    const urlParams = new URLSearchParams(window.location.search);
    const utm_campaign = urlParams.get('utm_campaign');
    const utm_medium = urlParams.get('utm_medium');
    const utm_source = urlParams.get('utm_source');

    if (utm_campaign && !localStorage.getItem('utm_campaign')) {
      localStorage.setItem('utm_campaign', utm_campaign);
    }
    if (utm_medium && !localStorage.getItem('utm_medium')) {
      localStorage.setItem('utm_medium', utm_medium);
    }
    if (utm_source && !localStorage.getItem('utm_source')) {
      localStorage.setItem('utm_source', utm_source);
    }
  }

  // Apply UTM parameters from localStorage to the form fields
  applyStoredUTMParamsToForm() {
    const form = document.querySelector('#customHubspotForm');
    if (form) {
      const utm_campaign = localStorage.getItem('utm_campaign') || '';
      const utm_medium = localStorage.getItem('utm_medium') || '';
      const utm_source = localStorage.getItem('utm_source') || '';

      const campaignField = form.querySelector('#utm_campaign__c');
      const mediumField = form.querySelector('#utm_medium__c');
      const sourceField = form.querySelector('#utm_source__c');

      if (campaignField) campaignField.value = utm_campaign;
      if (mediumField) mediumField.value = utm_medium;
      if (sourceField) sourceField.value = utm_source;
    }
  }

  // Bind section buttons to trigger checkbox selection
  bindSectionButtons() {
    document.querySelectorAll('[data-action="click->request-modal#open"][data-section]').forEach(button => {
      button.addEventListener('click', () => {
        this.clearAllCheckboxes();
        const section = button.getAttribute('data-section');

        setTimeout(() => {
          if (section === "All") {
            this.checkAllCheckboxes();
          } else {
            const checkbox = document.querySelector(`input[name="product_interest[]"][value="${section}"]`);
            if (checkbox) {
              checkbox.checked = true;
              this.triggerChangeEvent(checkbox);
            }
          }
        }, 500);
      });
    });
  }

  // Trigger a change event for checkboxes
  triggerChangeEvent(element) {
    const event = new Event('change', { bubbles: true });
    element.dispatchEvent(event);
  }

  // Clear all checkboxes
  clearAllCheckboxes() {
    document.querySelectorAll('input[name="product_interest[]"]').forEach(checkbox => {
      checkbox.checked = false;
      this.triggerChangeEvent(checkbox);
    });
  }

  // Check all checkboxes
  checkAllCheckboxes() {
    document.querySelectorAll('input[name="product_interest[]"]').forEach(checkbox => {
      checkbox.checked = true;
      this.triggerChangeEvent(checkbox);
    });
  }

  // When the form is submitted, ensure the UTM parameters are present
  async submitForm(event) {
    event.preventDefault();

    const form = event.target;

    if (!this.isAtLeastOneChecked()) {
      this.showErrorMessage();
      return;
    }

    this.hideErrorMessage();

    try {
      const recaptchaToken = await grecaptcha.execute(window.recaptchaSiteKey, { action: 'submit_pricing_form' });

      const recaptchaField = form.querySelector('#recaptchaToken');
      if (recaptchaField) recaptchaField.value = recaptchaToken;

      const formData = new FormData(form);
      const pageUri = window.location.href;
      const pageName = document.title;
      formData.append('pageUri', pageUri);
      formData.append('pageName', pageName);

      const response = await fetch(form.action, {
        method: 'POST',
        headers: { 'X-CSRF-Token': formData.get('authenticity_token') },
        body: formData
      });

      if (!response.ok) throw new Error('Form submission failed.');

      const result = await response.json();
      if (result.status === 'ok') {
        this.loadRevenueHeroWidget(result.hero_data.router_id, result.hero_data.fields);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }

  toggleSelectAll() {
    this.allSelected = !this.allSelected;
    this.productCheckboxTargets.forEach(checkbox => {
      checkbox.checked = this.allSelected;
    });

    this.selectAllButtonTarget.textContent = this.allSelected ? 'Deselect All' : 'Select All';
  }

  isAtLeastOneChecked() {
    return this.productCheckboxTargets.some(checkbox => checkbox.checked);
  }

  showErrorMessage() {
    this.errorContainerTarget.classList.remove('hidden');
  }

  hideErrorMessage() {
    this.errorContainerTarget.classList.add('hidden');
  }

  loadRevenueHeroWidget(routerId, fields) {
    const hero = new RevenueHero({ routerId: routerId, showLoader: true });
    hero.submit(fields).then(rhData => {
      hero.dialog.open(rhData);
    }).catch(error => console.error('Error with RevenueHero:', error));
  }
}
