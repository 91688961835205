import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slides", "frame", "carousel", "button"];

  connect() {
    this.scrollAmount = 250; // Adjust as needed
    console.log("Carousel Controller connected");

    // Initialize the focused button and boundary check
    this.initializeFocusedButton();
    this.checkScrollLimits();

    // Set up event listener for managing focus outside the carousel
    document.addEventListener('click', this.handleOutsideClick.bind(this));
  }

  disconnect() {
    // Clean up the event listener when the controller disconnects
    document.removeEventListener('click', this.handleOutsideClick.bind(this));
  }

  initializeFocusedButton() {
    this.currentlyFocusedButton = this.buttonTargets.find(button => button.hasAttribute('autofocus')) || this.buttonTargets[0];

    if (this.currentlyFocusedButton) {
      // Remove the native autofocus to prevent initial scroll
      this.currentlyFocusedButton.removeAttribute('autofocus');

      // Wait for the page to fully load and stabilize the scroll position
      setTimeout(() => {
        requestAnimationFrame(() => {
          this.currentlyFocusedButton.focus({ preventScroll: true });
        });
      }, 0);
    }
  }


  selectImage(event) {
    const index = parseInt(event.currentTarget.dataset.index, 10);
    const slideWidth = this.frameTarget.offsetWidth;
    const offset = slideWidth * index;

    this.slidesTarget.style.transform = `translateX(-${offset}px)`;
    console.log(`Selecting image at index: ${index}, offset: ${offset}`);
    this.currentlyFocusedButton = event.currentTarget;
  }

  scrollButtonCarousel(event) {
    const direction = event.currentTarget.dataset.direction;
    const carousel = this.carouselTarget;
    const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;
    const targetScrollLeft = Math.max(0, Math.min(carousel.scrollLeft + (direction === "left" ? -this.scrollAmount : this.scrollAmount), maxScrollLeft));

    console.log(`Scrolling carousel ${direction}, scrollAmount: ${targetScrollLeft - carousel.scrollLeft}`);

    // Use custom smooth scroll to avoid native smooth scroll issues
    this.smoothScroll(carousel, targetScrollLeft);
  }

  smoothScroll(carousel, targetScrollLeft) {
    const step = () => {
      const distance = targetScrollLeft - carousel.scrollLeft;
      const scrollBy = Math.sign(distance) * Math.min(Math.abs(distance), 20); // Adjust for smoothness/speed

      if (Math.abs(distance) > 1) {
        carousel.scrollLeft += scrollBy;
        requestAnimationFrame(step); // Continue until close to target
      } else {
        carousel.scrollLeft = targetScrollLeft; // Snap to target to avoid minor overshoot
      }

      console.log(`Carousel scrollLeft after scroll: ${carousel.scrollLeft}`);
    };

    requestAnimationFrame(step);
  }

  handleOutsideClick(event) {
    const isClickInsideCarousel = this.carouselTarget.contains(event.target);
    const isClickInsideModal = document.querySelector('#bookingModal')?.contains(event.target);
    const isClickInsideForm = document.querySelector('#customHubspotForm')?.contains(event.target);

    if (!isClickInsideCarousel && !isClickInsideModal && !isClickInsideForm) {
      this.currentlyFocusedButton?.focus({ preventScroll: true });
    }
  }

  checkScrollLimits() {
    const carousel = this.carouselTarget;
    const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;
    console.log("Initial carousel scrollLeft:", carousel.scrollLeft);
    console.log("Carousel maxScrollLeft:", maxScrollLeft);
  }
}