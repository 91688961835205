import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]

  connect() {
    this.startScroll()
  }

  startScroll() {
    let position = 0
    const scrollSpeed = 0.5 // Adjust the speed as necessary
    const contentWidth = this.contentTarget.scrollWidth / 2 // Total width of the first set of images

    const scrollContent = () => {
      position -= scrollSpeed

      // Apply the scrolling transformation
      this.contentTarget.style.transform = `translateX(${position}px)`

      // If scrolled past the first set of images, reset position to the beginning of the second set
      if (Math.abs(position) >= contentWidth) {
        this.contentTarget.style.transition = 'none' // Disable transition for immediate reset
        position = 0 // Reset the position
        this.contentTarget.style.transform = `translateX(${position}px)` // Apply the reset transformation
        // Re-enable the transition after a tiny delay for continuous scrolling
        setTimeout(() => {
          this.contentTarget.style.transition = 'transform 0.1s linear'
        }, 50)
      }

      requestAnimationFrame(scrollContent)
    }

    scrollContent()
  }
}