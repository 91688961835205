import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item']
  static values = { class: String, threshold: Number, rootMargin: String }

  initialize () {
    this.intersectionObserverCallback = this.intersectionObserverCallback.bind(this)
  }

  connect (){
    this.class = this.classValue || this.defaultOptions.class || 'in'
    this.threshold = this.thresholdValue || this.defaultOptions.threshold || 0.5
    this.rootMargin = this.rootMarginValue || this.defaultOptions.rootMargin || '0px'

    this.observer = new IntersectionObserver(this.intersectionObserverCallback, this.intersectionObserverOptions)
    this.itemTargets.forEach(item => this.observer.observe(item))
  }

  disconnect (){
    this.itemTargets.forEach(item => this.observer.unobserve(item))
  }

  intersectionObserverCallback (entries, observer){
    entries.forEach(entry => {
      if (entry.intersectionRatio > this.threshold) {
        const target = entry.target
        target.style.height = 'inherit'
        for (let i = 0; i < target.children.length; i++) {
          target.children[i].classList.remove("hidden")
        }
        observer.unobserve(target)
      }
    })
  }

  get intersectionObserverOptions () {
    return {
      threshold: this.threshold,
      rootMargin: this.rootMargin
    }
  }

  get defaultOptions () {
    return {}
  }
}