import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

	static classes = [ "hidden" ]
	static targets = [ 
		"formError",
		"email",
		"companyName",
		"name",
		"employeeRange",
		"phone",
		"country",
		"countryName",
		"countryCode",
		"callingCode",
		"loading"
	]
  
	connect() {
		this.formElement = this.element
		gtag('event', 'open_booked_call_modal');
	}

	// // This method does all the marketing systems as soon as we get a valid email
	// validateEmailAndDoMarketingStuff() {
	// 	if (this.validateEmail(this.emailTarget.value)) {
	// 		// clearbit.identify(this.emailTarget.value, {
	// 		// 	'email': this.emailTarget.value,
	// 		// 	'company': this.companyNameTarget.value
	// 		// });
	// 		// var pardotIframe = document.createElement("iframe");
	// 		// pardotIframe.src = "https://go.workforce.com/l/966793/2022-03-08/2j83?email=" + this.emailTarget.value + "&signup_type=partial_marketing_site_free_trial";
	// 		// document.body.appendChild(pardotIframe);
	// 	}
	// }

  	// submit() {
	// 	if (this.nameTarget.value == "") {
	// 			this.formErrorTarget.innerHTML = "Please enter your name.";
	// 			event.preventDefault()
			
	// 	} else if (this.companyNameTarget.value == "") {
	// 		this.formErrorTarget.innerHTML = "Please enter a company name.";
	// 		event.preventDefault()

	// 	} else if (!this.validateEmail(this.emailTarget.value)) {
	// 		this.formErrorTarget.innerHTML = "Try entering a valid email address.";
	// 		event.preventDefault()

	// 	} else if (this.phoneTarget.value == "") {
	// 			this.formErrorTarget.innerHTML = "Please enter your phone number.";
	// 			event.preventDefault()

	// 	} else if (this.employeeRangeTarget.value == "") {
	// 		this.formErrorTarget.innerHTML = "Please enter your staff range.";
	// 		event.preventDefault()

	// 		} else {
	// 			this.loadingTarget.classList.remove(this.hiddenClass)
	// 		gtag('event', 'booked_call');
	// 		window.lintrk('track', { conversion_id: 6345762 });
		
	// 	}		
		
	// 	/**
	// 	 * if we have any values in errorMessage array
	// 	 * 		display all error messages 
	// 	 */
	// }

  	// validateEmail(email) {
	//  	if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
	// 	  return true
	// 	} else {
	// 	  return false
	// 	}
	// }

	
}

