import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "subItem"];

  connect() {
    // Adding event listeners to each <summary> element for click actions.
    this.itemTargets.forEach((item) => {
      const summary = item.querySelector("summary");
      if (summary) {
        summary.addEventListener("click", (event) => this.handleItemClick(event, item));
      }
    });

    this.subItemTargets.forEach((subItem) => {
      const summary = subItem.querySelector("summary");
      if (summary) {
        summary.addEventListener("click", (event) => this.handleSubItemClick(event, subItem));
      }
    });
  }

  handleItemClick(event, item) {
    // Prevent the default behavior to avoid toggle issues
    event.preventDefault();

    // Close all other items at the same level.
    this.itemTargets.forEach((otherItem) => {
      if (otherItem !== item && otherItem.hasAttribute("open")) {
        otherItem.removeAttribute("open");
      }
    });

    // Toggle the open state of the clicked item
    if (item.hasAttribute("open")) {
      item.removeAttribute("open");
    } else {
      item.setAttribute("open", "");
    }
  }

  handleSubItemClick(event, subItem) {
    // Prevent the default behavior to avoid toggle issues
    event.preventDefault();

    // Close all other sub-items at the same level.
    this.subItemTargets.forEach((otherSubItem) => {
      if (otherSubItem !== subItem && otherSubItem.hasAttribute("open")) {
        otherSubItem.removeAttribute("open");
      }
    });

    // Toggle the open state of the clicked sub-item
    if (subItem.hasAttribute("open")) {
      subItem.removeAttribute("open");
    } else {
      subItem.setAttribute("open", "");
    }
  }
}
