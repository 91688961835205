// app/javascript/controllers/collapsible_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["paragraph"]

  connect() {
    this.currentParagraph = null; // Track the currently open paragraph
  }

  toggle(event) {
    const newParagraph = event.currentTarget.nextElementSibling.querySelector("[data-collapsible-target='paragraph']");

    // Close the previous paragraph if it's open and not the current one
    if (this.currentParagraph && this.currentParagraph !== newParagraph) {
      this.currentParagraph.hidden = true; // Close the previous one
    }

    // Toggle the visibility of the new paragraph
    newParagraph.hidden = !newParagraph.hidden;

    // Update the reference for the currently open paragraph
    this.currentParagraph = newParagraph.hidden ? null : newParagraph;
  }
}