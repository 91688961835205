import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["panel", "bg", "header", "howDidYouHear", "payrollCheckbox"];

  open(event) {
    const clickedButton = event.currentTarget;

    if (clickedButton.id === "bannerButton") {
      console.log("Opening from Banner Button");
      this.setHeaderText("Get 2 Months Free!");
      this.prefillHowDidYouHear("Payroll Promotion");
      this.precheckPayrollCheckbox();
    } else {
      console.log("Opening from 'Get a Demo' Button");
      this.setHeaderText("Book a Demo");
    }

    this.showModal();
  }

  showModal() {
    console.log("Opening Modal");
    enter(this.panelTarget);
    enter(this.bgTarget);
  }

  close(event) {
    if (event.target === event.currentTarget) {
      console.log("Closing Modal");
      leave(this.panelTarget);
      leave(this.bgTarget);
      this.resetForm();
    }
  }

  setHeaderText(text) {
    if (this.hasHeaderTarget) {
      console.log(`Setting Header Text: ${text}`);
      this.headerTarget.textContent = text;
    }
  }

  prefillHowDidYouHear(value) {
    if (this.hasHowDidYouHearTarget) {
      console.log(`Pre-filling 'How Did You Hear': ${value}`);
      this.howDidYouHearTarget.value = value;
    }
  }

  precheckPayrollCheckbox() {
    if (this.hasPayrollCheckboxTarget) {
      console.log("Pre-checking Payroll Checkbox");
      this.payrollCheckboxTarget.checked = true;
    }
  }

  resetForm() {
    console.log("Resetting Form");
    if (this.hasHowDidYouHearTarget) {
      this.howDidYouHearTarget.value = "";
    }
    if (this.hasPayrollCheckboxTarget) {
      this.payrollCheckboxTarget.checked = false;
    }
  }
}