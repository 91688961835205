import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  toggleModal() {
    this.modalTarget.classList.toggle("hidden");
  }

  closeModal(event) {
    if (event.target === this.modalTarget) {
      this.modalTarget.classList.add("hidden");
    }
  }

  keepOpen(event) {
    event.stopPropagation(); // Prevent click inside the modal from closing it
  }
}
