import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "dropdown"]

  connect() {
    console.log("connected");
    this.filter(); // Call the filter method when the controller connects
  }

  filter(event) {
    const category = event ? event.target.value : this.dropdownTarget.value;
    console.log("filter connected", category);
    this.contentTargets.forEach((job) => {
      if (category === "" || job.dataset.category === category) {
        job.classList.remove("hidden");
      } else {
        job.classList.add("hidden");
      }
    });
  }
}