// app/javascript/controllers/storylane_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]

  connect() {
    this.demoUrls = {
      "staff_changes": "https://app.storylane.io/share/qzlwkxz7fjmi",
      "inviting_staff": "https://app.storylane.io/share/vokhbaphl7wt",
      "scheduling": "https://app.storylane.io/share/9ggji49wc956",
      "copying_shifts": "https://app.storylane.io/share/hdouerdil1zk",
      "approving_staff": "https://app.storylane.io/share/jiclk9yaznn6",
      "time_off": "https://app.storylane.io/share/q42rytoo42it",
      "communications": "https://app.storylane.io/share/dclxcotxfn0u",
      "shift_swap": "https://app.storylane.io/share/ngkn1ozekxah",
      "change_address": "https://app.storylane.io/share/ps7r6gbz3fuc",
      "attendance_write_up": "https://app.storylane.io/share/ssnj5d2wftun",
      "performance_improvement_plan": "https://app.storylane.io/share/c0rphmqu5rnd",
      "performance_review": "https://app.storylane.io/share/b265dgnbvhot",
      "disciplinary_action": "https://app.storylane.io/share/ybqyrtergnbn",
      "job application": "https://app.storylane.io/share/rqgdg2xqls5n",
      "building schedules": "https://app.storylane.io/share/twtqg1ahxs7j",
      "time_off_requests": "https://app.storylane.io/share/z6m8rueeypuz",

      // Additional mappings as needed
    }
  }

  play(event) {
    const button = event.currentTarget
    const demoUrl = this.demoUrls[button.id]

    if (demoUrl) {
      Storylane.Play({
        type: 'popup',
        demo_type: 'html',
        width: 1920,
        height: 1080,
        scale: '1',
        demo_url: demoUrl,
        padding_bottom: '56.25%'
      })

      // Uncomment and customize the following lines for active states or color changes
      // this.clearActiveStates()
      // button.classList.add('active')
      // const section = button.dataset.section
      // if (section) {
      //   button.classList.add(`active-${section}`)
      // }
      // button.classList.add('storylane-text-white')
    } else {
      console.error("Demo URL not found for button ID:", button.id)
    }
  }

  // clearActiveStates() {
  //   this.buttonTargets.forEach(button => {
  //     button.classList.remove('active', 'active-scheduling', 'active-hr', 'active-payroll')
  //   })
  // }
}