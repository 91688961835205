import { Controller } from '@hotwired/stimulus'
import { throttle } from 'lodash'

export default class extends Controller {
  initialize () {
    this.scroll = this.scroll.bind(this)
  }

  connect () {
    this.scroll = throttle(this.scroll, 15)
    window.addEventListener('scroll', this.scroll, { passive: true })
    this.scroll()
  }

  disconnect () {
    window.removeEventListener('scroll', this.scroll)
  }

  scroll () {
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
    const width = (window.scrollY / height) * 100
    this.element.style.width = `${width}%`
  }
}