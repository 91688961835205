import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

	static classes = [ "hidden" ]
	static targets = [
		"formError",
		"email",
		"companyName",
		"name",
		"phone",
		"country",
		"countryName",
		"countryCode",
		"callingCode",
		"loading"
	]

 connect() {
  	this.formElement = this.element
	gtag('event', 'open_booked_call_modal');
  }

  // This method does all the marketing systems as soon as we get a valid email
  email() {
	if (this.validateEmail(this.emailTarget.value)) {
	this.adroll(this.emailTarget.value);
	clearbit.identify(this.emailTarget.value, {
	  'email': this.emailTarget.value,
	  'company': this.companyNameTarget.value
	});

  }

}

  submit() {
    if (this.nameTarget.value == "") {
      this.formErrorTarget.innerHTML = "Please enter your name.";
      event.preventDefault()
    } else if (this.companyNameTarget.value == "") {
      this.formErrorTarget.innerHTML = "Please enter a company name.";
      event.preventDefault()
    } else if (!this.validateEmail(this.emailTarget.value)) {
      this.formErrorTarget.innerHTML = "Try entering a valid email address.";
      event.preventDefault()
    } else if (this.phoneTarget.value == "") {
  		this.formErrorTarget.innerHTML = "Please enter your phone number.";
  		event.preventDefault()
  	} else {
  	  this.loadingTarget.classList.remove(this.hiddenClass)
      gtag('event', 'booked_call');
	  window.lintrk('track', { conversion_id: 6345762 });
  }

  validateEmail(email) ;
	 	if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
		  return true
		} else {
		  return false
		}
	}

	  // This method takes a valid sign up and creates a pixel for Rollworks.
	  adroll(email) {
		var img = document.createElement('img');
		img.src = '//d.adroll.com/emailc/UCPH4PS57NDJXF45NH22TQ/5H3YCWMLVBHWNCDRL5FQZE?' +
				  'adroll_external_data=ipixel%3D1%26adroll_email%3D' +
				  encodeURIComponent(email);
		document.body.appendChild(img) //without this line the image wasn't loaded onto the page.
		try {
			__adroll.record_user({"adroll_segments": "990a7981"});
		} catch(err) {}
	  }

}

