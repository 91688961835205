import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['navbar', 'waypoint'];
  static classes = ['transformed'];
  static threshold = 0.9;

  initialize() {
    console.log(this.transformedClasses);
    this.isTransformed = false;
  }

  connect() {
    if (this.navbarTarget && this.waypointTarget) {
      window.addEventListener('scroll', this.handleScroll.bind(this));
    }
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll() {
    const waypointTop = this.waypointTarget.getBoundingClientRect().top;
    const threshold = this.waypointTarget.offsetHeight * (1 - this.constructor.threshold);

    if (waypointTop <= threshold && !this.isTransformed) {
      this.transformedClasses.forEach((klass) => this.navbarTarget.classList.add(klass));
      ["text-white", "bg-gradient-to-r", "from-blue-900", "via-blue-700", "to-blue-600"].forEach((klass) => this.navbarTarget.classList.remove(klass));
      document.querySelectorAll("svg.navbar-logo").forEach((logo => logo.querySelectorAll(".cls-1").forEach((path) => path.style.fill = "#333")))
      this.navbarTarget.querySelectorAll("button").forEach((button) => button.classList.remove("text-white"));
      this.navbarTarget.querySelectorAll("button").forEach((button) => button.classList.add("text-black"));
      this.navbarTarget.querySelectorAll("a").forEach((link) => link.classList.add("text-black"));
      this.isTransformed = true;
    
    } else if (waypointTop > threshold && this.isTransformed) {
      this.transformedClasses.forEach((klass) => this.navbarTarget.classList.remove(klass));
      ["text-white", "bg-gradient-to-r", "from-blue-900", "via-blue-700", "to-blue-600"].forEach((klass) => this.navbarTarget.classList.add(klass));
      this.navbarTarget.querySelectorAll("button").forEach((button) => button.classList.add("text-white"));
      this.navbarTarget.querySelectorAll("button").forEach((button) => button.classList.remove("text-black"));
      document.querySelectorAll("svg.navbar-logo").forEach((logo => logo.querySelectorAll(".cls-1").forEach((path) => path.style.fill = "#FFF")))
      this.navbarTarget.querySelectorAll("a").forEach((link) => link.classList.remove("text-black"));
      this.isTransformed = false;
    }
  }
}