import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["countdown", "banner"];

  connect() {
    this.initializeCountdown();
  }

  initializeCountdown() {
    const countDownDate = new Date("Dec 31, 2024 23:59:59").getTime();

    this.timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      const daysString = `<span class="countdown-item">${days} ${days === 1 ? 'day' : 'days'}</span>`;
      const hoursString = `<span class="countdown-item">${hours} ${hours === 1 ? 'hour' : 'hours'}</span>`;
      const minutesString = `<span class="countdown-item">${minutes} min</span>`;
      const secondsString = `<span class="countdown-item">${seconds} sec</span>`;

      this.countdownTarget.innerHTML = `
        ${daysString} ${hoursString} ${minutesString} ${secondsString}`;

      if (distance < 0) {
        clearInterval(this.timer);
        this.countdownTarget.innerHTML = "EXPIRED";
      }
    }, 1000);
  }

  disconnect() {
    clearInterval(this.timer);
  }

  hideBanner() {
    console.log("Closing banner"); // Check if this log appears
    if (this.hasBannerTarget) {
      console.log("Banner found, hiding it."); // Verify the target exists
      this.bannerTarget.style.display = "none";
    } else {
      console.error("Banner target not found.");
    }
  }
}